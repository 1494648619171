<template>
  <div class="detail-page">
    <common-header></common-header>
    <div class="detail-content">
      <!-- banner图片 -->
      <div class="about-banner">
        <img class="banner-font" :src="require('../../assets/en/detail/bannerFont.png')" alt="图片文字" />
      </div>
      <!-- 弦乐 Qin String -->
      <div class="detail-product">
        <!-- 试看 -->
        <div class="detail-try-see">
          <div class="video-online">
            <div class='musci-video'>
              <el-image class="video-back" :src="nowVideo.pic" />
              <el-image class="video-play" @click="playVideo()" :src="require('../../assets/img/playBack.png')" />
              <div>{{nowVideo.name}}</div>
            </div>
          </div>
          <div class="detail-product-right">
            <img :src="require('@/assets/en/detail/products.png')" />
            <div>The sampling of Qin Strings was completed at Jintian Recording Studio, the largest orchestral recording studio in Beijing, China with a line - up of 8-6-4-4-2 players. At the early phase of the sampling, the use of SSL mixer enables our sound engineer to easily acquire warm tone which is more appropriate for popular music of this age. </div>
          </div>
        </div>
        <!-- 试听 -->
        <div class="detail-try-listen">
          <div class="music-audio">
            <musciAudio :nowmusic="nowmusic"></musciAudio>
          </div>
          <el-image class="musci-buy" @click="buyFunc" :src="require('../../assets/en/home/Buy.png')" />
        </div>
      </div>
      <!-- 产品优势 -->
      <div class="detail-advantage">
        <el-image class="advantage-title-img" :src="require('@/assets/en/detail/advantage.png')" />
        <div class="advantage-info">
          <div class="advantage-info-left">
            <div class="info-left-title">Perfect Sampling of Pizzicato and Staccato With Almost Zero latency</div>
            <div class="info-left-text">
              <div>Qin Strings has fundamentally resolved the issue of latency. That being said, there is almost 0 latency. Our consumers may check with a metronome while arranging and producing music using our product in order to figure out if there is any latency. There shouldn’t be any problems.</div>
              <div>The amplitude of trills on the left hand could be controlled and adjusted by MIDI controller CC4. If you set the value under 50, there will be minor second trills. On the other hand, it you set the value above 50, the trills will turn into major second trills. In addition, the sample of Pizzicato and the sampling of Staccato are both outstanding.</div>
            </div>
          </div>
          <div class="advantage-info-right" style="margin-top: 150px">
            <video-inner :nowmusic="caifangVideo" :heightStyle="'406px'" :widthStyle="'696px'"></video-inner>
          </div>
        </div>
        <div class="advantage-info advantage-info-special">
          <div class="advantage-info-right">
            <el-image class="one-img" :src="require('@/assets/zh/detail/keyword1.png')" />
            <div class="two-img-box">
              <el-image :src="require('@/assets/zh/detail/keyword2.png')" />
              <el-image :src="require('@/assets/zh/detail/keyword3.png')" />
            </div>
          </div>
          <div class="advantage-info-left">
            <div class="info-left-title">
              <span>Experience the software and hardware linkage</span>
              <span>with the NI Komplete S series keyboard</span>
            </div>
            <div class="info-left-text">
              <div>Qin Strings was officially packed by NI and it was compatible with the full product line of NI hardware products. Our product will be sold in the form of NI standard library which means our customers will be delivered an activation code directly from Native Access.</div>
              <div>At the same time, you may obtain perfect experience on NI S Series Keyboard because all parameters on Qin strings may be adjusted through NI keyboards.</div>
            </div>
          </div>
        </div>
        <div class="advantage-info advantage-info-special2">
          <div class="advantage-info-left">
            <div class="info-left-title">About the Recording of Qin Strings</div>
            <div class="info-left-text">
              <div>The recording of Qin Strings took place in Jintian Recording Studio. Our recording engineer Mr Wang Xiaosi has abundant experience strings recording.</div>
              <div>During the entire recording process, Mr Chen Qinyang as the producer, supervised the recording studio. He strove for excellence with no detail spared. Each sample has been amplifed by SSL Mixer and taken care of by meticulous EQ processing at the early stage in order to ensure that the sample with extremely high quality could be acquired and the sampling of each legato was meticulously fine-adjusted in order to restore the realism of legato.</div>
            </div>
          </div>
          <div class="advantage-info-right">
            <el-image :src="require('@/assets/zh/detail/microphone.png')" alt=""></el-image>
          </div>
        </div>
        <div class="advantage-info advantage-info-special3">
          <div class="advantage-info-right">
            <video-inner :nowmusic="stringRubbingVideo" :heightStyle="'100%'" :widthStyle="'100%'"></video-inner>
          </div>
          <div class="advantage-info-left">
            <div class="info-left-title"> Outstanding and Realistic Legato and Vibrato</div>
            <div class="info-left-text">
              <div>Vibrato will be triggered by CC11 MIDI Controller. The default value 0 of CC11 signifes fast vibrato, the value between 25%-49%percent signifes slow vibrato, the value between 50%-100% signifies no vibrato.</div>
              <div>CC4 is the MIDI controller that determines whether the trill is a major second trill or minor second trill. At the default value 0, the trill is a minor second trill. When the value is between 50%-100%, the trill will become a major second trill. Like other strings sample libraries, CC1is the control of expression.</div>
            </div>
          </div>
        </div>
      </div>
      <!--购买按钮  -->
      <div class="buy-now" v-if="scroll>=1600">
        <img class="process-back-buy" :style="{'top': '80vh'}" @click="buyFunc" :src="$http + '/en/home/buyCol.png'" alt="">
      </div>
    </div>
    <common-footer></common-footer>
    <video-outside v-if="videoDialog" :nowmusic="nowVideo" @closeVideo='closeVideo'></video-outside>
    <order-online v-if="orderDialog" ref="orderDialog" @closeOnline='closeOnline'></order-online>
  </div>
</template>

<script>
import commonFooter from "../../components/en/commonFooter.vue";
import commonHeader from "../../components/en/commonHeader.vue";
import videoOutside from '../../components/videoOutside.vue'
import musciAudio from '../../components/musciAudio.vue'
import videoInner from '../../components/videoInner.vue'
// 在线订单组件
import orderOnline from '../../components/en/orderOnline.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
    musciAudio,
    videoOutside,
    videoInner,
    orderOnline
  },
  data(){
    return {
      videoDialog: false, // 视频弹窗dialo
      // 视频内容 -- 弦乐试看
      nowVideo: {
        link: this.$movie + '/mp4/chinese_style3.mp4',
        pic: this.$http + '/zh/detail/trySee.png',
        name: 'String Session Demo'
      },
      // 音频内容 -- 弦乐试听
      nowmusic: {
        link: require('../../assets/mp3/movie_audition.mp3'),
        pic: this.$http + '/zh/detail/tryListen.png',
        name: 'String Session Demo'
      },
      // 产品优势的采访
      caifangVideo: {
        link: this.$movie + '/mp4/caifang.mp4',
        pic: '',
        name: '',
        poster: this.$http + '/img/caifangBack.jpg'
      },
      // 联奏和揉弦视频
      stringRubbingVideo: {
        link: this.$movie + '/mp4/product_show.mp4',
        pic: '',
        name: '',
        poster: this.$http + '/img/product_showBack.jpg'
      },
      scroll: 0,
      scrollBottom: null,
      orderDialog: false, // 在线订单
    }
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll)
    if(this.$route.query.tradeNo){
      this.orderDialog = true
      // this.stopMove()
    }
  },
  destroyed(){
    window.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    // 监听滚动
    handleScroll(){
      // 变量 scrollHeight 是滚动条的总高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        // 变量 windowHeight 是可视区的高度
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        //或者使用document.querySelector('.class或者#id').scrollTop
        this.scroll = document.documentElement.scrollTop||document.body.scrollTop
        this.scrollBottom = scrollHeight - windowHeight - this.scroll
        console.log(this.scrollBottom);
    },
    // 播放视频
    playVideo(){
      this.videoDialog = true
      // this.stopMove()
    },
    // 关闭视频
    closeVideo(){
      this.videoDialog = false
      // this.Move()
    },
    // 立即购买
    buyFunc(){
      this.orderDialog = true
      // this.stopMove()
    },
    closeOnline(){
      this.orderDialog = false
      // this.Move()
      if(this.$route.query.tradeNo){
        this.$router.push({
          path: this.$route.path
        })
      }
      // this.Move()
    },
    //停止页面滚动
    stopMove(){
      let m = function(e){e.preventDefault();};
      document.body.style.overflow='hidden';
      document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    //开启页面滚动
    Move(){
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
    }
  }
}
</script>

<style scoped lang='scss'>
.detail-page{
  width: 100%;
  height: 100%;
  .detail-content{
    width: 100%;
    height: 100%;
    // 顶部banner
    .about-banner {
      padding-top: 90px;
      width: 100%;
      height: 814px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("../../assets/zh/detail/banner.jpg");
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      
      .banner-font {
        width: 1300px;
        height: 209px;
        margin-top: 265px;
      }
    }
    // 弦乐 Qin Strings
    .detail-product{
      width: 100%;
      padding: 80px 251px 127px 251px;
      box-sizing: border-box;
      background: #000;
      .detail-try-see{
        display: flex;
        justify-content: space-between;
        .video-online{
          width: 697px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .musci-video{
            position: relative;
            width: 697px;
            height: 389px;
            margin-bottom: 80px;
            .video-back{
              width: 100%;
              height: 419px;
            }
            .video-play{
              position: absolute;
              left: 50%;
              top: 50%;
              width: 60px;
              height: 60px;
              transform: translate(-50%, -50%);
              cursor: pointer;
            }
            div{
              margin-top: 6px;
              height: 33px;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              line-height: 33px;
              text-align: center;
            }
          }
        }
        .detail-product-right{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          &>img{
            width: 623px;
            height: 152px;
          }
          &>div{
            margin: 60px 0 0 30px;
            width: 590px;
            height: 336px;
            font-size: 24px;
            font-family: PingFang SC;
            color: rgba(255,255,255,.8);
            line-height: 48px;
            // text-align: justify;
          }
        }
      }
      .detail-try-listen{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .music-audio{
          width: 697px;
          height: 235px;
        }
        .musci-buy{
          width: 264px;
          height: 70px;
          margin-bottom: 33px;
          cursor: pointer;
        }
      }
    }
    // 产品优势
    .detail-advantage{
      width: 100%;
      padding: 0 252px 0 253px;
      box-sizing: border-box;
      background: url('../../assets/zh/detail/microBack.png') no-repeat,black;
      background-size: 100% 926px;
      background-position-y: 2020px;
      .advantage-title-img{
        margin-left: -21px;
        width: 696px;
        height: 152px;
      }
      .advantage-info{
        margin-top: 68px;
        display: flex;
        justify-content: space-between;
        .advantage-info-left{
          .info-left-title{
            width: 614px;
            height: 84px;
            font-size: 28px;
            color: #fff;
            line-height: 42px;
            font-family: Source-KeynoteartHans;
          }
          .info-left-text{
            margin-top: 60px;
            width: 620px;
            height: 624px;
            font-size: 24px;
            font-family: PingFang SC;
            color: rgba(255,255,255,.8);
            line-height: 48px;
            text-indent: 2em;
            // text-align: justify;
          }
        }
        .advantage-info-right{
          width: 740px;
          height: 377px;
        }
      }
      .advantage-info-special{
        margin-top: 125px;
        padding-bottom: 131px;
        .advantage-info-right{
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 840px;
          margin-top: 150px;
          .one-img{
            width: 840px;
            height: 420px;
            margin-bottom: 24px;
          }
          .two-img-box{
            width: 840px;
            height: 202px;
            display: flex;
            justify-content: space-between;
            .el-images{
              width: 404px;
              height: 202px;
            }
          }
        }
        .advantage-info-left{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          height: 649px;
          margin-left: -140px;
          .info-left-title{
            width: 708px;
            height: 84px;
            text-align: right;
            span{
              display: block;
            }
          }
          .info-left-text{
            width: 470px;
            height: 576px;
          }
        }
      }
      .advantage-info-special2{
        margin-top: 77px;
        padding-bottom: 150px;
        .advantage-info-left{
          .info-left-title{
            width: 614px;
            height: 42px;
          }
          .info-left-text{
            width: 666px;
            height: 576px;
          }
        }
        .advantage-info-right{
          width: 645px;
          height: 473px;
          margin-top: 110px;
          .el-image{
            width: 100%;
            height: 100%;
          }
        }
      }
      .advantage-info-special3{
        padding-top: 120px;
        padding-bottom: 79px;
        .advantage-info-right{
          width: 651px;
          height: 435px;
          margin-top: 70px;
        }
        .advantage-info-left{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
          height: 632px;
          margin-top: -80px;
          .info-left-title{
            width: 502px;
            height: 84px;
            text-align: right;
          }
          .info-left-text{
            width: 662px;
            height: 480px;
            line-height: 48px;
          }
        }
      }
    }
    // 立即购买
    .process-back-buy{
      position: fixed;
      right: 42px;
      width: 70px;
      height: 70px;
      cursor: pointer;
      z-index: 888;
    }
  }
}
</style>